import "./App.css";
import "@shopify/polaris/build/esm/styles.css";

import React from "react";

import { Link as ReactRouterLink } from "react-router-dom";

import ProductImageReviewer from "./ProductImageReviewer.tsx";

import enTranslations from "@shopify/polaris/locales/en.json";

import { AppProvider /*, EmptyState, Card*/ } from "@shopify/polaris";

import reportWebVitals from "./reportWebVitals";
//@ts-ignore
import { metric } from "./APIUtils.ts";

const { createApp } = window["app-bridge"] ?? { createApp: null };

const IS_EXTERNAL_LINK_REGEX = /^(?:[a-z][a-z\d+.-]*:|\/\/)/;

function Link({ children, url = "", external, ref, ...rest }) {
  // react-router only supports links to pages it can handle itself. It does not
  // support arbirary links, so anything that is not a path-based link should
  // use a reglar old `a` tag
  if (external || IS_EXTERNAL_LINK_REGEX.test(url)) {
    rest.target = "_blank";
    rest.rel = "noopener noreferrer";
    return (
      <a href={url} {...rest}>
        {children}
      </a>
    );
  }
  return (
    <ReactRouterLink to={url} {...rest}>
      {children}
    </ReactRouterLink>
  );
}

let webVitalReportState = {};

function App({ shop, host }) {
  // See: https://shopify.dev/tutorials/get-and-store-the-shop-origin

  let app;

  if (shop && host) {
    const config = {
      apiKey: process.env.REACT_APP_SHOPIFY_API_KEY,
      host: host, //btoa(shop + "/admin"),
    };

    if (createApp) {
      app = createApp({
        apiKey: config.apiKey,
        host: config.host,
        forceRedirect: true,
      });
    }

    reportWebVitals(({ id, name, value }) => {
      // Only report each web vital once. Ignore duplicate callbacks possibly caused by Shopify's separate web vitals implementation.
      if (!webVitalReportState[name]) {
        webVitalReportState[name] = true;
        metric(app, `performance_${name.toLowerCase()}`, {
          value: value,
          id: id,
          name: name,
        });
      }
    });

    return (
      <AppProvider i18n={enTranslations} linkComponent={Link}>
        {app ? (
          <ProductImageReviewer shop={shop} app={app} />
        ) : (
          <ProductImageReviewer shop={shop} app={app} />
        )}
      </AppProvider>
    );
  } else {
    // const queryParams = new URLSearchParams(window.location.search);
    // const imageDataUrl = decodeURIComponent(queryParams.get("imageDataUrl"));
    // console.log(imageDataUrl);

    // const link = document.createElement("a");
    // link.href = imageDataUrl;
    // link.download = "mockup.png";
    // link.click();

    return (
      <AppProvider>
        No shop found...
        {/* <ProductImageReviewer shop={shop} app={app} /> */}
        {/* <Card sectioned>
          <EmptyState heading="No shop found">
            <p>
              You should launch this Shopify app via the Shopify admin if
              already installed, otherwise you can install it via:
              <br />
              <br />
              <b>https://[this-domain]/api/auth?shop=[your-shop-uri]</b>
            </p>
          </EmptyState>
        </Card> */}
      </AppProvider>
    );
  }
}

export default App;

import React, { RefObject, useEffect, useMemo, useRef, useState } from "react";

function useOnScreen(ref: RefObject<HTMLElement>) {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) =>
        setIntersecting(entry.isIntersecting)
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ref]
  );

  useEffect(() => {
    observer.observe(ref.current as any);
    return () => observer.disconnect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isIntersecting;
}

export default function HoverImage({ imageSrc }) {
  const ref = useRef<HTMLDivElement>(null);
  const isVisible = useOnScreen(ref);

  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    if (!isVisible) {
      setImageLoaded(false);
    }
  }, [isVisible]);

  return (
    <div ref={ref}>
      {isVisible && (
        <img
          src={imageSrc}
          hidden={!imageLoaded}
          onLoad={() => setImageLoaded(true)}
          alt="Full preview"
          style={{
            zIndex: 1000,
            pointerEvents: "none",
            position: "absolute",
            top: "-8em",
            left: "6.5em",
            minWidth: "5em",
            minHeight: "5em",
            maxWidth: "25em",
            maxHeight: "25em",
            backgroundColor: "#ccc",
            borderRadius: 4,
            borderWidth: 10,
            borderStyle: "solid",
            borderColor: "#fff",
            boxShadow: "0px 0px 15px 1px rgba(0, 0, 0, 0.2)",
            overflow: "hidden",
          }}
        />
      )}
    </div>
  );
}

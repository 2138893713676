import React from "react";
// import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client";
import "./index.css";
// import * as Sentry from "@sentry/react";
// import { BrowserTracing } from "@sentry/tracing";
import App from "./App";
// import LocalDevApp from "./LocalDevApp.tsx";
// import reportWebVitals from "./reportWebVitals";

import { BrowserRouter } from "react-router-dom";
import queryString from "query-string";

// Sentry.init({
//   dsn: "https://5e98073909f643d184bfcd81b9c7adbf@o930848.ingest.sentry.io/6264457",
//   integrations: [new BrowserTracing()],

//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// });

function AppWrapper() {
  const shop = queryString.parse(window.location.search).shop;
  const host = queryString.parse(window.location.search).host;

  return (
    <BrowserRouter>
      <App shop={shop} host={host} />
      {/* <LocalDevApp /> */}
    </BrowserRouter>
  );
}

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <AppWrapper />
  </React.StrictMode>
);

import React, { useState, useEffect } from "react";

import "@shopify/polaris/build/esm/styles.css";
import { Tabs } from "@shopify/polaris";

//@ts-ignore
import ProductPanel from "./ProductPanel.tsx";
//@ts-ignore
import ScenePanel from "./ScenePanel.tsx";
//@ts-ignore
import ExportPanel from "./ExportPanel.tsx";

//@ts-ignore
import { metric } from "./APIUtils.ts";

function SettingsPanel({
  inputsHandler,
  inputField,
  isInches,
  handleImageFileChange,
  artworkImage,
  croppedArtworkImage,
  setCroppedArtworkImage,
  loadingArtworkImage,
  imageAspectRatio,
  scenes,
  customScenes,
  artworkStyle,
  saveImage,
  grabImageBlob,
  selectedProduct,
  setLoadingArtworkImage,
  automaticSceneSelection,
  setAutomaticSceneSelection,
  app,
  templateMode,
  allowLinkToVariant,
  betaMode,
  watermark,
  manageSubscriptionCallback,
}) {
  const [activePanelIndex, setActivePanelIndex] = useState(0);

  /* eslint-disable  @typescript-eslint/no-unused-vars */
  const [detectedSize, setDetectedSize] = useState([0, 0]);
  /* eslint-enable  @typescript-eslint/no-unused-vars */

  // Load image for selected product
  useEffect(() => {
    // Fetch image and call handleImageFileChange
    if (selectedProduct && selectedProduct.images[0]) {
      setLoadingArtworkImage(true);
      fetch(selectedProduct.images[0].originalSrc, {
        method: "GET",
      }).then((res) => {
        res.blob().then((image) => {
          handleImageFileChange(image);
        });
      });
    } else {
      handleImageFileChange(null);
    }
  }, [selectedProduct]); //eslint-disable-line

  useEffect(() => {
    // Flip dimensions if appropriate
    if (detectedSize[0] > 0 && detectedSize[1] > 0) {
      let w = detectedSize[0];
      let h = detectedSize[1];
      if (
        (imageAspectRatio > 1 && w / h < 1) ||
        (imageAspectRatio < 1 && w / h > 1)
      ) {
        let newW = h;
        let newH = w;
        w = newW;
        h = newH;
      }
      inputsHandler((w * 100).toFixed(1).toString(), "width");
      inputsHandler((h * 100).toFixed(1).toString(), "height");
    }
  }, [imageAspectRatio, detectedSize]); //eslint-disable-line

  // // Search for size options in selected product
  // useEffect(() => {
  //   if (selectedProduct) {
  //     // Extract dimensions from Size key
  //     // Regex playground here: https://rubular.com/r/ApsWQlG6jA8Z0g
  //     var pattern = new RegExp( //eslint-disable-next-line
  //       /([0-9\.]+\s*[0-9\.]+\s*\/[0-9\.]+|[0-9\.]+\s*\/[0-9\.]+|[0-9\.]+)\s?(["']|mm|cm|m)?(\s*[xX]\s*([0-9\.]+\s*[0-9\.]+\s*\/[0-9\.]+|[0-9\.]+\s*\/[0-9\.]+|[0-9\.]+)\s?(["']|mm|cm|m)?)(\s*[xX]\s*([0-9\.]+\s*[0-9\.]+\s*\/[0-9\.]+|[0-9\.]+\s*\/[0-9\.]+|[0-9\.]+)\s?(["']|mm|cm|m)?)?/g
  //     );

  //     let sizeOptions;
  //     selectedProduct.options.forEach((option: any) => {
  //       if (option.name === "size" || option.name === "Size") {
  //         sizeOptions = option;
  //       }
  //     });

  //     if (sizeOptions) {
  //       let sizeFound = false;
  //       sizeOptions.values.forEach((_sizeOption: string) => {
  //         let sizeOption = _sizeOption.toLowerCase().toString();
  //         sizeOption = sizeOption.replace(
  //           /[\u201C\u201D\u2033\u2018\u2019]/g,
  //           '"'
  //         );

  //         /*
  //         To support 3 part dimensions (e.g. 20 x 40 x 1) AND 2 part dimensions we have this weird check for regex result position 8 (i.e. unit component for 3 part), falling back to position 5 (2 part unit component)
  //         */
  //         var match: any;
  //         var possibleMatch: any;
  //         while ((possibleMatch = pattern.exec(sizeOption))) {
  //           if (possibleMatch[8] || possibleMatch[5]) {
  //             match = possibleMatch;
  //           }
  //         }
  //         let w = null;
  //         let h = null;

  //         let foundMatchWithUnits = false;
  //         if (match) {
  //           const unitComponent = match[8] || match[5];
  //           if (unitComponent && unitComponent !== "") {
  //             foundMatchWithUnits = true;
  //             // let i = 0;
  //             // match.forEach((m: any) => {
  //             //   console.log("[" + i + "]" + m);
  //             //   i++;
  //             // });
  //             // console.log("w: " + match[1] + ", h: " + match[4] + " (" + match[5] + ")");

  //             w = parseFloat(
  //               normaliseToMetres(match[1], unitComponent).toFixed(4)
  //             ); // 4 decimal places, i.e. to nearest 0.1mm
  //             h = parseFloat(
  //               normaliseToMetres(match[4], unitComponent).toFixed(4)
  //             );
  //           }
  //           if (!foundMatchWithUnits) {
  //             if (sizeOption.includes("a5")) {
  //               w = 0.21;
  //               h = 0.148;
  //             } else if (sizeOption.includes("a4")) {
  //               w = 0.297;
  //               h = 0.21;
  //             } else if (sizeOption.includes("a3")) {
  //               w = 0.42;
  //               h = 0.297;
  //             } else if (sizeOption.includes("a2")) {
  //               w = 0.594;
  //               h = 0.42;
  //             } else if (sizeOption.includes("a1")) {
  //               w = 0.841;
  //               h = 0.594;
  //             }
  //           }
  //         }
  //         if (w && h) {
  //           // console.log("Found: " + w + ", " + h);
  //           if (!sizeFound) {
  //             // inputsHandler((w * 100).toFixed(1).toString(), "height"); // TODO: why are these flipped? long term I guess orientation should be based on the provided (cropped) image?
  //             // inputsHandler((h * 100).toFixed(1).toString(), "width");
  //             setDetectedSize([w, h]);
  //             sizeFound = true;
  //           }
  //         } else {
  //           console.log("Could not identify: " + sizeOption);
  //         }
  //       });
  //     }
  //   }
  // }, [selectedProduct]); //eslint-disable-line

  const tabs = [
    {
      id: "artwork",
      content: "Artwork",
    },
    {
      id: "scene",
      content: "Scene",
    },
    // {
    //   id: "publish",
    //   content: "Publish",
    // },
  ];

  return (
    <>
      {/* <div
        style={{
          paddingTop: 15,
          paddingLeft: 15,
          paddingRight: 15,
          paddingBottom: 4,
        }}
      >
        <Stack distribution="fillEvenly">
        
          
          <ButtonGroup segmented fullWidth={true}>
            <Button
              pressed={activePanelIndex === 0}
              onClick={() => setActivePanelIndex(0)}
            >
              Product
            </Button>
            <Button
              pressed={activePanelIndex === 1}
              onClick={() => setActivePanelIndex(1)}
            >
              Scene
            </Button>
            <Button
              pressed={activePanelIndex === 2}
              onClick={() => setActivePanelIndex(2)}
            >
              Export
            </Button>
          </ButtonGroup>
        </Stack>
      </div> */}
      {/* <div
        style={{
          display: "flex",
          height: "5rem",
          borderBottom: "0.0625rem solid var(--p-border-subdued)",
          backgroundColor: "var(--p-background)",
          fontSize: "14px",
          color: "#4B4D4F",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            width: "100%",
            padding: "0 5px 0 15px",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "inline-block",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              paddingRight: "5px",
            }}
          >
            {selectedProduct && selectedProduct.title}
          </div>

          <div>
            <ButtonGroup segmented>
              <Button
                size="slim"
                icon={SearchMinor}
                onClick={() => setResourcePickerOpen(true)}
              ></Button>
              <Popover
                active={moreActionsOpen}
                activator={activator}
                autofocusTarget="first-node"
                onClose={() => setMoreActionsOpen(true)}
              >
                <ActionList
                  actionRole="menuitem"
                  sections={[
                    {
                      title: "Product options",
                      items: [
                        {
                          content: "View in admin",
                          icon: ProductsMajor,
                          onAction: () => {
                            const productIdClean = selectedProduct.id
                              .split("/")
                              .pop();
                            redirect?.dispatch(Redirect.Action.ADMIN_PATH, {
                              path: `/products/${productIdClean}`,
                              newContext: true,
                            });
                          },
                        },
                        {
                          content: "View on storefront",
                          icon: StoreMinor,
                          onAction: () => {
                            redirect?.dispatch(Redirect.Action.REMOTE, {
                              url: `https://${shop}/products/${selectedProduct.handle}`,
                              newContext: true,
                            });
                          },
                        },
                      ],
                    },
                    {
                      title: "General (beta)",
                      items: shopIsInBulkBeta
                        ? [
                            {
                              content: "Product Media Review",
                              icon: ImagesMajor,
                              onAction: () => {
                                setProductImageReviewerOpen(true);
                              },
                            },
                            {
                              content: "Bulk Rendering",
                              icon: WandMajor,
                              onAction: () => {
                                setBulkRenderMode(true);
                              },
                            },
                          ]
                        : [],
                    },
                  ]}
                />
              </Popover>
            </ButtonGroup>
          </div>
        </div>
      </div> */}
      <Tabs
        tabs={tabs}
        selected={activePanelIndex}
        onSelect={(index) => {
          setActivePanelIndex(index);
          if (index === 1) {
            metric(app, "mockup_editor_scene_tab_opened");
          }
        }}
        fitted
      >
        <div style={{ padding: 15 }}>
          {activePanelIndex === 0 && (
            <ProductPanel
              inputsHandler={inputsHandler}
              inputField={inputField}
              isInches={isInches}
              handleImageFileChange={handleImageFileChange}
              artworkImage={artworkImage}
              croppedArtworkImage={croppedArtworkImage}
              setCroppedArtworkImage={setCroppedArtworkImage}
              loadingArtworkImage={loadingArtworkImage}
              selectedProduct={selectedProduct}
              templateMode={templateMode}
              allowLinkToVariant={allowLinkToVariant}
            />
          )}
          {activePanelIndex === 1 && (
            <ScenePanel
              inputsHandler={inputsHandler}
              inputField={inputField}
              scenes={scenes}
              customScenes={customScenes}
              automaticSceneSelection={automaticSceneSelection}
              setAutomaticSceneSelection={setAutomaticSceneSelection}
              app={app}
              watermark={watermark}
              betaMode={betaMode}
              manageSubscriptionCallback={manageSubscriptionCallback}
            />
          )}
          {activePanelIndex === 2 && (
            <ExportPanel
              saveImage={saveImage}
              grabImageBlob={grabImageBlob}
              selectedProduct={selectedProduct}
              artworkStyle={artworkStyle}
              app={app}
            />
          )}
        </div>
      </Tabs>
    </>
  );
}

/* eslint-disable  @typescript-eslint/no-unused-vars */
function normaliseToMetres(value: number, units: string) {
  if (units) {
    switch (units.toLowerCase()) {
      case "m":
        return value;
      case "cm":
        return value * 0.01;
      case "mm":
        return value * 0.001;
      case '"':
      case "″":
      case "”":
      case "'":
      case "in":
        return value * 0.0254;
    }
  }
  return 0;
}
/* eslint-enable  @typescript-eslint/no-unused-vars */

export default SettingsPanel;

import React, { useState } from "react";

import "@shopify/polaris/build/esm/styles.css";
import {
  Button,
  Banner,
  Modal,
  TextContainer,
  Heading,
} from "@shopify/polaris";
// import { Modal } from "@shopify/app-bridge-react";
import { Redirect } from "@shopify/app-bridge/actions";

//@ts-ignore
import { authenticatedFetch } from "./APIUtils.ts";

function ExportPanel({
  saveImage,
  grabImageBlob,
  selectedProduct,
  artworkStyle,
  app,
}) {
  const [showModal, setShowModal] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [uploadComplete, setUploadComplete] = useState(false);

  const [capturedImage, setCapturedImage] = useState(null);
  const [capturedImageBlob, setCapturedImageBlob] = useState("");

  const captureImage = async () => {
    const image = await grabImageBlob();
    setCapturedImage(image);

    const blobUrl = URL.createObjectURL(image as Blob);
    setCapturedImageBlob(blobUrl);
  };

  const redirect = app && Redirect.create(app);

  return (
    <>
      {" "}
      <Button
        primary
        fullWidth
        disabled={!selectedProduct}
        onClick={async () => {
          await captureImage();
          setUploading(false);
          setUploadComplete(false);
          setShowModal(true);
        }}
      >
        Publish to Online Store
      </Button>
      <br />
      <Button fullWidth onClick={() => saveImage()}>
        Download image
      </Button>
      <br />
      {selectedProduct ? (
        <>
          {/* <Banner
            status="warning"
            action={{
              content: "Go to product",
              onAction: () => {
                const productIdClean = selectedProduct.id.split("/").pop();
                redirect.dispatch(Redirect.Action.ADMIN_PATH, {
                  path: `/products/${productIdClean}`,
                  newContext: true,
                });
              },
            }}
            title="Publishing is a beta feature"
          >
            Please check product media to avoid publishing duplicate images.
          </Banner> */}
        </>
      ) : (
        <Banner>
          To publish this image directly to your online store you first need to
          select a product on the products tab
        </Banner>
      )}
      <Modal
        title={uploadComplete ? "Image has been published" : ""}
        primaryAction={
          uploadComplete
            ? {
                content: "Go to product",
                destructive: false,
                onAction: () => {
                  const productIdClean = selectedProduct.id.split("/").pop();
                  redirect?.dispatch(Redirect.Action.ADMIN_PATH, {
                    path: `/products/${productIdClean}`,
                    newContext: true,
                  });
                  setShowModal(false);
                },
              }
            : {
                content: "Publish image",
                destructive: true,
                loading: uploading,
                onAction: async () => {
                  const image = capturedImage; // TODO: what if this hasn't finished setting yet? or something went wrong?

                  try {
                    // setPostingTier(tier);

                    setUploading(true);

                    // Get an image upload URL
                    const responseRaw = await authenticatedFetch(
                      app,
                      `/api/products/generateUploadUrl`,
                      {
                        method: "POST",
                        body: JSON.stringify({}),
                        headers: {
                          "content-type": "application/json",
                          accept: "application/json",
                        },
                      }
                    );
                    const response = await responseRaw.json();

                    if (response.result === "success") {
                      // console.log(`Should be redirecting to: ${response.confirmationUrl}`);
                      // redirect.dispatch(Redirect.Action.REMOTE, response.confirmationUrl);

                      // console.log(
                      //   "We're going to PUT to: " + response.uploadUrl
                      // );
                      // console.log(
                      //   "PUT params: " + JSON.stringify(response.parameters)
                      // );

                      // Upload image file to S3
                      await fetch(response.uploadUrl, {
                        method: "PUT",
                        headers: {
                          content_type: "image/jpeg", //hardcoded...
                          acl: "private", //hardcoded...
                        },
                        body: image,
                      }); // TODO: validate response

                      // console.log("File available at: " + response.resourceUrl);

                      await authenticatedFetch(
                        app,
                        `/api/products/${selectedProduct.handle}/publishImage/`,
                        {
                          method: "POST",
                          body: JSON.stringify({
                            productId: selectedProduct.id,
                            imageUrl: response.resourceUrl,
                            artworkStyle: artworkStyle,
                          }),
                          headers: {
                            "content-type": "application/json",
                            accept: "application/json",
                          },
                        }
                      ); // TODO: validate response

                      setUploadComplete(true);
                    } else {
                      // setPostingTier(null);
                      // setPostingFailed(true);
                    }
                  } catch (error) {
                    // setPostingTier(null);
                    // setPostingFailed(true);
                  }

                  // setUploading(true);
                  // setTimeout(
                  //   function () {
                  //     //Start the timer
                  //     setUploadComplete(true);
                  //   }.bind(this),
                  //   1500
                  // );
                },
              }
        }
        secondaryActions={
          uploadComplete
            ? []
            : [
                {
                  content: "Cancel",
                  onAction: () => {
                    setShowModal(false);
                  },
                },
              ]
        }
        open={showModal}
        onClose={() => {
          setShowModal(false);
        }}
      >
        <Modal.Section>
          {uploadComplete ? (
            <>Please review your product media in the Shopify Admin</>
          ) : (
            <>
              <TextContainer>
                <Heading>Are you sure you want to publish this image?</Heading>
                <p>
                  Product: <i>{selectedProduct.title}</i>
                </p>
                <img
                  style={{ width: "100%", height: "100%" }}
                  src={capturedImageBlob}
                  alt=""
                />
              </TextContainer>
            </>
          )}
        </Modal.Section>
      </Modal>
    </>
  );
}

export default ExportPanel;
